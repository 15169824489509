import React from 'react'
import OrsHeader from '../OrsHeader/OrsHeader'
import OrsSurvey from '../Survey/survey'
import './App.css';

// class mainApp extends ReactComponent

class MainApp extends React.Component{
  constructor(props) {
    super(props)
    this.handleSurveyCompletion = this.handleSurveyCompletion.bind(this);
    this.state = {isComplete: false};
  }

  handleSurveyCompletion() {
    this.setState(prev_state => ({
      isComplete: !prev_state.isComplete
    }));
  }

  render() {
    const isComplete = this.state.isComplete;

    return(
    
    <div className="App">
      <OrsHeader finish={isComplete}/>
      <div className="Survey"><OrsSurvey/></div>
    </div>
    );
  }

}

export default MainApp