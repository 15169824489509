import * as Survey from "survey-react";
import "survey-react/survey.css";
import React, { useState } from 'react'
import axios from "axios";
import config from "../../constants";
import survey from "./survey.json"

const OrsSurvey = () => {

  const model = new Survey.Model(survey);

  const storageName = "SurveyJS_state";

  function loadState(survey) {
    const data = window.localStorage.getItem(storageName);
    console.log(data);
    if (data) {
      const res = JSON.parse(data);
      if (res.data) survey.data = res.data;
      if (res.currentPageNo) survey.currentPageNo = res.currentPageNo;
    }
  }

  function saveState(survey) {
    var res = { currentPageNo: survey.currentPageNo, data: survey.data };
    try {
      window.localStorage.setItem(storageName, JSON.stringify(res));
    } catch(e) {
      console.error(e);
      alert("Cannot save survey data to local storage");
    }
  }

  function clearStorage() {
    window.localStorage.removeItem(storageName);
  }

  model.onValueChanged.add(saveState);
  model.onCurrentPageChanged.add(saveState);

  model.onComplete.add(survey => {
    saveState(survey);
    axios.post(config.serverUrl, survey.data)
      .then(() => {
          survey.completedHtml ="<h2>Success! Thank you for completing the survey!</h2><br/><br/><br/><h2>Your request is being reviewed.  Due to the large number of requests this may take up to 2 business days.</h2>";
        survey.render();
        clearStorage();
      })
      .catch(err => {
        survey.completedHtml=`<h2>An error occurred: ${err.message}</h2>`;
        survey.render();
      });
  });

  loadState(model);

  return <Survey.Survey model={model} />;

};

export default OrsSurvey
